@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'BrownStd';
    src: url('../../public/fonts/BrownStdRegular.otf');
    font-weight: 400;
}

@font-face {
    font-family: 'BrownStd';
    src: url('../../public/fonts/BrownStdBold.otf');
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../public/fonts/Roboto-Regular.ttf');
    font-weight: 500;
}

body {
    margin: 0;
    font-family: 'BrownStd', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
